//
import { GET_BUSINESS_PRODUCTS_QUERY, GET_PRODUCTS_QUERY, GET_PRODUCT_QUERY } from '../../graphql/product'
import { apolloClient } from '../../graphql/apollo'



export const products = {
    namespaced: true,
    state: {
        products: []
     },
    getters: {
        allProducts: (state) => {
            //
            const data = state.products.sort((a, b) => a.product.localeCompare(b.product))
            
            return data
        },
        getProduct: (state) => {
            //
            const data = state.products
        
            return data
        },
        searchProduct: state => (search) => {
            //
            const results = state.products.filter(({ product, business, category, tags }) => product.toLowerCase().includes(search.toLowerCase()) || business.toLowerCase().includes(search.toLowerCase()) || category.toLowerCase().includes(search.toLowerCase()) || tags.includes(search.toLowerCase()))

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchMainProduct: state => (search) => {
            
            const results = state.products.filter(({ product, business, category, tags }) => product.toLowerCase().includes(search.toLowerCase()) || business.toLowerCase().includes(search.toLowerCase()) || category.toLowerCase().includes(search.toLowerCase()) || tags.includes(search.toLowerCase()))

            const allBusiness =  results.map(product => product.business)
            const businesses  = [...new Set(allBusiness)]

            return businesses
        }, 
        searchBusinessProduct: state => (search) => {
            //
            const results = state.products.filter(({ product }) => product.toLowerCase().includes(search.toLowerCase()) )

            return results
        }
        // currentBusiness: state => (name) => {
        //     // if (business) { return }
        
        //     const results = state.vendors.find(({ business }) => business.includes(name))
        
        //     return results
        // }
    },
    actions: {
        async getProducts({ state,commit },  product ) {
            //
            let resp
            let data
            //
            if (product) {
                resp = await apolloClient.query({ query: GET_PRODUCTS_QUERY, variables: { business: product } })
                data = resp.data.allProducts

            }  else {
                //
                // resp = await apolloClient.query({ query: GET_ALL_PRODUCTS_QUERY })
                data = []
                // data = resp.data.products
            }

            (function () {
                for (let index = 0; index < data.length; index++) {
    
                    // to 2 decimalplaces
                    data[index].qty = 0

                    // set prices
                    data[index].sprice = data[index].margin ? Math.round( (data[index].price * ((100 + data[index].margin)/100)) * 100 )/100 : data[index].price
                }
            })()

            commit('updateProducts', data)
        },
        async getBusinessProducts({ state,commit },  businesses) {
            //
            let resp
            let data
            //
            if (businesses.length > 0) {
                resp = await apolloClient.query({ query: GET_BUSINESS_PRODUCTS_QUERY, variables: { business: businesses } })
                data = resp.data.businessProducts

            } else {
                data = []
            }

            (function () {
                for (let index = 0; index < data.length; index++) {
    
                    // to 2 decimalplaces
                    data[index].qty = 0
                    // set prices
                    data[index].sprice = data[index].margin ? Math.round( (data[index].price * ((100 + data[index].margin)/100)) * 100 )/100 : data[index].price
                }
                })()

            commit('updateProducts', data)

            
        }
    },
    mutations: {
        updateProducts: (state, data) => {
            state.products = data
        }
    }
}

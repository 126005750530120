<template>
    <div>
        <div class="p-5">
            <!-- <icon
                type="chevron-back"
                class="m-4"
                tag="router-link"
                to="/"
                style="top:0;position:absolute"
            ></icon> -->
            <feather tag="router-link" size="30" :to="prevPath" type="x" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <h4 class="title is-4 ml-5 pl-5">Delivery address</h4>
            
            <GoogleMaps :height="'calc(100vh - 175px)'" :type="type" style=""/>
            
            <b-button v-if="addressChanged" @click="redirect"> Done </b-button>
            <div hidden>
                <div class="">
                    <div class="" style="max-width:460px;">
                        <b-field label="">
                            <b-input @input.native="onSubmit" @keyup.native="load" :loading="loading" autofocus v-model="searchString" placeholder="Enter an address "></b-input>
                        </b-field>
                    </div>
                </div>
                <ul class="menu-list" style="max-width:460px;">
                    <li>
                        
                        <a class="my-1">
                            <p class="title is-6" style="font-weight: 300">Spintex Coastal</p>
                            <p class="subtitle is-6">Kpeshie</p>
                        </a>
                        <hr class="m-0 p-0">
                    </li>
                    <li>
                        <a class="my-1">
                            <p class="title is-6" style="font-weight: 300">Airport City</p>
                            <p class="subtitle is-6">Accra</p>
                        </a>
                        <hr class="m-0 p-0">
                    </li>
                </ul>

            </div>
        </div>
        
    </div>
</template>

<script>

    import GoogleMaps from '../components/GoogleMaps'

    export default {
        name: 'mapView',
        data() {
            return {
                searchString: '',
                addressChanged: false,
                loading: false,
                prevRoute: null,
                type: 'address',
            }
        },
        components: {
            GoogleMaps,
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        computed: {
            prevPath() { return this.prevRoute ? this.prevRoute : '/' },
            
            address() {
                return this.$store.getters['vendors/address']
            }

        },
        mounted(){
            // console.log(this.$route)

            if (Object.keys(this.$route.params).length !== 0){
                //
                if (typeof(this.$route.params.refer) !== "undefined" && this.$route.params.refer == "checkout" ) {
                    //
                    console.log('From Checkout')
                    this.type = "checkout"
                } else {
                    this.type = 'address'
                }
            }
        },
        watch: {
            address(){
                if (this.address.address && this.$route.params.refer == "checkout"){
                    //
                    
                } else if (this.address.address) {
                    this.$buefy.toast.open('Successfull')
                    //
                    this.addressChanged = true;
                    setTimeout(()=>{
                        this.$router.back()
                    }, 1200)
                }
            }
        },
        methods: {
            redirect() {
                this.$router.back()
            },
            onSubmit() {
                if (this.searchString.length >= 3) {
                    console.log(this.searchString)
                }
            }, 
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            }
        }
    }
</script>

<style lang="scss" scoped>
    // custom
    @import '@/assets/scss/custom_variables.scss';

    .dark {
        color: $dark;
    }
</style>
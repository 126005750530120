<template>
    <div>
        <div class="sidebar-wrapper">
            <SideNav />
        </div>
        <!-- {{ vendors }} -->
        
        <div v-if="!isLoading && allBusiness.length > 0" class="p-5" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            <!-- <VendorSkeleton/> -->
            <div class="pb-4" hidden>
                <h1 class="title mb-1 is-4">Fresh Groceries<span style="font-weight:300;"></span> </h1>
                <p class=" is-4 has-text-grey">Delivery Quickly</p>
            </div>
            <b-carousel :indicator-inside="false">
                <b-carousel-item v-for="(carousel, i) in (sliders.length > 0 ? sliders : carousels)" :key="i" @click="render(carousel)">
                    <section :class="`hero is-medium slider-image`">
                    <!-- <section :class="`hero is-medium slider-image`" :style="`background-image: url(${carousel.img})`"> -->
                        <b-image 
                            :ratio="`3by1` " 
                            class="image" 
                            :lazy="true"
                            :src="carousel.img"
                        ></b-image>
                        <!-- <div class="hero-body has-text-centered">
                            <h4 class="title is-4 white">{{carousel.text}}</h4>
                        </div> -->
                    </section>
                </b-carousel-item>
            </b-carousel>
            <!-- <div class="space mt-2"></div> -->
            <div v-if="mostRated.length > 0">
                <div class="pt-4">
                    <h1 class="title is-5 has-text-weight-semibold"> <feather color="green" type="thumbs-up" size="22"></feather> Most Rated <span style="font-weight:300;"> </span> </h1>
                    <!-- <p class="subtitle is-4">Delivery Quickly</p> -->
                </div>
                <VendorTemplate v-if="mostRated.length > 0" :classes="'columns cov is-mobile'" :business="mostRated" />
                <!-- <VendorSkeleton v-else/> -->
                <!-- <div class="space mt-2"></div> -->
            </div>
            <div v-if="discountedMenu.length > 0" class="pt-4">
                <h1 class="title is-5 has-text-weight-semibold"><feather type="percent" size="22"></feather> Discounted <span style="font-weight:300;"> </span> </h1>
                <!-- <p class="subtitle is-4">Delivery Quickly</p> -->
            </div>
            <VendorTemplate v-if="discountedMenu.length > 0" :classes="'columns cov is-mobile'" :business="discountedMenu" />
            <!-- <VendorSkeleton v-if="isLoading && discountedMenu.length == 0"/> -->
            <!-- <div class="space mt-2"></div> -->
            <div class="pt-4" v-if="freeDelivery.length > 0">
                <h1 class="title is-5 has-text-weight-semibold"><b-icon  size="is-" pack="" icon="bike"></b-icon> Free Delivery <span style="font-weight:300;"> </span> </h1>
                <!-- <p class="subtitle is-4">Delivery Quickly</p> -->
            </div>
            <VendorTemplate v-if="freeDelivery.length > 0" :classes="'columns cov is-mobile'" :business="freeDelivery" />
            <!-- <VendorSkeleton v-else/> -->
            <div class="pt-4" v-if="newBusiness.length > 0">
                <h1 class="title is-5 has-text-weight-semibold"><b-icon  size="is-" pack="" icon="star"></b-icon> New Business <span style="font-weight:300;"> </span> </h1>
                <!-- <p class="subtitle is-4">Delivery Quickly</p> -->
            </div>
            <VendorTemplate v-if="newBusiness.length > 0" :classes="'columns cov is-mobile'" :business="newBusiness" />
            <!-- <VendorSkeleton v-else/> -->
            <div class="pt-4" v-if="allBusiness.length > 0">
                <h1 class="title is-5 has-text-weight-semibold">All Vendors <span style="font-weight:300;"> </span> </h1>
                <!-- <p class="subtitle is-4">Delivery Quickly</p> -->
            </div>
            <VendorTemplate v-if="allBusiness.length > 0" :classes="'columns cov is-mobile'" :business="allBusiness" />
            <!-- <VendorSkeleton v-else/> -->
            <div :style="cartCount > 0 ? 'padding: 45px' : 'padding: 5px'"></div>

            
        </div>
        <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        <div v-else-if="!isLoading && allBusiness.length == 0" class="p-4">
            <div class="text-center p-4" style="max-width:300px;margin:auto">
                
                <h4 class="title is-5  "> !Oops, sorry we don't deliver here </h4>
                <p class="has-text-weight-medium has-text-grey subtitle">We are currently deliver to. </p>
            </div>
            <hr class="m-2">

            <ul v-if="mapLocations.length !== 0"  class="menu-list columns is-multiline is-mobile pt-1 px-4" style="">
                <li v-for="loc in mapLocations" :key="loc.address" class="column is-half-mobile is-one-third-tablet is-one-fourth-desktop p-1">
                    <a @click="setAddress(loc)" class="my-1">
                        <p class="title is-6 is-flex is-align-items-center" style="font-weight: 300">
                        <feather type="map-pin" class="pr-3"> </feather>
                                {{ loc.address.split(',')[0] }}</p>
                                <!-- <p class="subtitle is-6">Kpeshie</p> -->
                        </a>
                    <hr class="m-0 p-0">
                </li>
            </ul>
            <!-- <div class="p-4 columns is-multiline ">
                <div class="column  is-one-third-fourthalf-tablet is-one-fourth-desktop">
                    <span class="is-flex is-align-items-center ">
                        <feather type="map-pin"></feather>
                        <h5 class="title is-6 ml-2 ">Spintex Road</h5>
                    </span>
                </div>
                <div class="column is-one-third-fourthalf-tablet is-one-fourth-desktop">
                    <span class="is-flex is-align-items-center ">
                        <feather type="map-pin"></feather>
                        <h5 class="title is-6 ml-2 ">East legon </h5>
                    </span>
                </div>
                <div class="column is-one-third-fourthalf-tablet is-one-fourth-desktop">
                    <span class="is-flex is-align-items-center ">
                        <feather type="map-pin"></feather>
                        <h5 class="title is-6 ml-2 ">Airport </h5>
                    </span>
                </div>
                <div class="column is-one-third-fourthalf-tablet is-one-fourth-desktop">
                    <span class="is-flex is-align-items-center ">
                        <feather type="map-pin"></feather>
                        <h5 class="title is-6 ml-2 ">Airport </h5>
                    </span>
                </div>
            </div> -->
        </div>
        <MainFooter/>

    </div>
</template>

<script>

    import { mapGetters, mapActions} from 'vuex'
    
    // import gql from 'graphql-tag'
    import SideNav from '../components/sidenav'
    // import VendorSkeleton from '../components/VendorSkeleton'
    import VendorTemplate from '../components/VendorTemplate'
    
    import axios from 'axios'
    import MainFooter from '../components/footer'

    export default {
        name: 'IndexPage',
        data(){
            return {
                sliders: [],
                carousels: [
                    { text: 'Slide 1', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1683538038/sliders/slider1_ocxome.jpg' },
                    { text: 'Slide 2', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1683538038/sliders/slider2__rfeeuj.jpg' },
                    { text: 'Slide 2', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1683538038/sliders/slider3_ejiqh9.jpg' },
                    { text: 'Slide 2', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1683538205/sliders/slider3__kwthvx.jpg' },
                    { text: 'Slide 3', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1683538205/sliders/slider3__kwthvx.jpg', link: 'vendor', business: 'Veggies Mart' },
                    { text: 'Slide 3', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1683538205/sliders/slider3_ejiqh9.jpg', link: 'vendor', business: 'Veggies Mart', params: 'Carrot' },
                    // { text: 'Slide 2', img: require('@/assets/img/slider/5.jpg') }
                    
                ],
                mapLocations: [
                    { address: 'Spintex Road, Accra', location: {"lat":5.6376136,"lng":-0.1266064}},
                    { address: '37 Area, Accra', location: {"lat":5.593157600000001,"lng":-0.1794512}},
                    { address: 'East legon, Accra', location: {"lat":5.635265,"lng":-0.161027}},
                    { address: 'Airport Hills, Accra', location: {"lat":5.6040298,"lng":-0.1830524}},
                    { address: 'Airport Residential, Accra', location: {"lat":5.635265,"lng":-0.161027}},
                    { address: 'Tse Addo, Accra', location: {"lat":5.5802557,"lng":-0.1390462}},
                    { address: 'Rasta/Bush Road, Accra', location: {"lat":5.5820033,"lng":-0.1311349}},
                    // 'East legon',
                    // 'Trassaco',
                    // 'Airport Residential',
                    // 'Airport Hills',
                    // 'East Airport',
                    // 'Tesano',
                    // 'Labone',
                    // 'Sakumono',
                ],
                screenWidth: 0,
                lastPath: null,
                vendors: { },
                isLoading: false
            }
        },
        components: {
            SideNav,
            // VendorSkeleton,
            VendorTemplate,
            MainFooter
        },
        created() {
            // watch and fetch
            this.$watch(
            () => this.$route.$params,
            async () => {
                // this.$store.dispatch('vendors/getBusiness')
                
                // mapActions('vendors',['getBusiness'])
                this.getVendors()

            },
            { immediate: true }

            )

        },
        mounted(){
            this.updateScreenWidth();
            this.onScreenResize();
            this.getSliders()

            if (!this.address.address) {
                //
                this.$buefy.toast.open('Kindly set delivery address')
                this.$router.push('/mapview')
            }
        },
        computed: {
            ...mapGetters('cart', ['totalPrice']),
            ...mapGetters('cart', ['cartCount']),
            ...mapGetters('cart', ['getCartBusiness']),
            ...mapGetters('vendors', ['discountedMenu']),
            ...mapGetters('vendors', ['freeDelivery']),
            ...mapGetters('vendors', ['mostRated']),
            ...mapGetters('vendors', ['newBusiness']),
            ...mapGetters('vendors', ['allBusiness']),
            ...mapGetters('vendors', ['address'])
        },
        methods: {
            render(v) {

                if (v.business) {
                    //
                    this.postClick(v)

                    if (v.params) {
                        
                        this.$router.push({ name: v.link, query: { search: v.params, business: v.business } })

                    } else {
                        
                        this.$router.push({ name: v.link, query: { business: v.business } })
                        
                    }
                }
                // console.log(v)
            },
            getImgUrl(value) {
                return `/assets/img/slider/${value}`
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            getImage(img) {
                //
                const link = `http://localhost:4000/api/images/slider/${this.$store.getters['auth/getUser'].token}/*/${img}`
                // 'http://localhost:4000\\api\\streams\\'+token+'\\'+cpath.split("\\").join('*')+'\\'+ev.name
                return link
            },
            async getVendors() {

                this.isLoading = true

                await this.$store.dispatch('vendors/getBusiness').then(()=>{
                    this.isLoading = false
                })

                // const resp = await this.$apollo.provider.defaultClient.query({ query: GET_LOCATION_BUSINESS_QUERY, variables: { location: [5.6305863,-0.1474174]} });
                
                // const resp = await this.$apollo.provider.defaultClient.query({

                //                 query: gql`query businesses {
                //                             businesses {
                //                                     _id
                //                                     business
                //                                     type
                //                                     coverImage
                //                                     location
                //                                     country
                //                                     freeDelivery
                //                                     deliveryFee
                //                                     deliveryTime
                //                                     menuDiscount
                //                                     ratings
                //                                 }
                //                             }
                //                             `
                //             });

                // (function () {
                //     const data = resp.data.businesses
                //     for (let index = 0; index < data.length; index++) {
                //         // const element = array[index];
                //         const rateLen = data[index].ratings.length

                //         if (rateLen > 0) {
                //         // sum ratings
                //         const rate = (data[index].ratings.reduce((a, b) => a + b, 0)) / rateLen
                //         // to 2 decimalplaces
                //         data[index].rate = parseFloat(rate.toFixed(1))

                //         // data.push(data[index])
                //         }
                //     }
                // })()

                // this.vendors = resp.data.businesses
            },
            setAddress(loc) {
                //
                this.$store.dispatch('vendors/addPlace', { address: loc.address, location: loc.location })
                //
                this.getVendors()
            },
            getSliders() {
                //
                axios.get('/user/sliders').then(response=>{
                    //
                    const data = response.data
                    data.sort((a,b) => b.createdAt - a.createdAt)

                    this.sliders = data
                    // check 
                    

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            postClick(data) {
                //
                axios.post('/slider/click', { slider: data.business, user: {
                    countryCode: this.$store.getters['auth/getUser'].phone.substring(0,4),
                    tel: this.$store.getters['auth/getUser'].phone.substring(4)
                } } ).then(response=>{
                    //
                    console.log(response.data)              

                }).catch(error=>{
                    //
                    console.log(error.response.data)
                })
            },
        }
    }
</script>

<style lang="scss" >
    // custom
    @import '@/assets/scss/custom_variables.scss';

    .white {
        color: $white !important;
    }

    .carousel-items {
        border-radius: 1rem;
    }
    .slider-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    .space {
        padding: 5px;
    }

    .cov {
        overflow-x: scroll;
        -ms-overflow-style: none;
        -moz-overflow-style: none;
        scrollbar-width: none;
    }

    .cov::-webkit-scrollbar {
    display: none;
    }

    .cov .media-content {
        overflow: hidden;
    }

    .card-content  {
        padding: 1rem 1.25rem  !important;
    }

    .media-content .b-skeleton {
        margin:  0 !important;
    }
</style>
<template>
  <div :class="classes">
    <div v-for="shop in business" :id="shop._id" :key="shop._id" :class="(business.length == 1) ? 'column' : 'column is-three-fifths-mobile is-half-tablet is-half-desktop is-one-third-widescreen'" @click="redirectTo(shop)">
      <div class="card" style="">
        <div class="card-image">
          <figure class="image is-16by9">
            <!-- `${require('@/assets/img/business/'+shop.coverImage+'')}` -->
            <img loading="lazy" :src="shop.coverImage" :alt="shop.business">
          </figure>
          <b-field v-if="shop.menuDiscount > 0" class="discount" style="display:inline-block;">
            <b-tag rounded type="">
              -{{ shop.menuDiscount }}%
            </b-tag>
          </b-field>
          <b-field v-if="shop.deliveryTime !== '0'" class="mins" style="display:inline-block;">
            <b-tag rounded type="">
              {{ shop.deliveryTime }}
            </b-tag>
          </b-field>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-6 mb-1 has-text-weight-semibold">
                {{ 
                    screenWidth <= 560 ? 
                    (shop.business.length > 20 ? (business.length !== 1 ? shop.business.substr(0,20).concat("...") : shop.business) : shop.business)
                    :
                    shop.business
                }}
                <!-- {{ shop.business }} {{ product.country.currency }} -->
              </p>
              <p class=" is-5">
                <b-icon
                  v-if="shop.rate"
                  icon="star"
                  type="is-primary"
                /> {{ shop.rate }}  <b-tag rounded :type="(shop.freeDelivery) ? 'is-primary' : 'is-dark'">
                  {{ (shop.freeDelivery) ? 'FREE' : shop.country.currency+' '+shop.deliveryFee.toFixed(2) }}
                  <b-icon
                    icon="bike"
                    size="is-small"
                    class="px-3"
                  />
                </b-tag>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VendorTemplate",
  data() {
    return {
        screenWidth: 0
    }
  },
  props: {
    business: {
      required: true
    },
    classes: {
      type: String,
      required: true
    }
  },
  mounted(){
                this.updateScreenWidth();
            this.onScreenResize();
  },
  methods: {
    onScreenResize() {
        window.addEventListener("resize", () => {
            this.updateScreenWidth();
        });
    },
    updateScreenWidth() {
        this.screenWidth = window.innerWidth;
    },
    redirectTo (shop) {
      this.$router.push({ name: 'vendor', 
                          params: 
                            { 
                                business: shop.business, 
                                type: shop.type, 
                                phone: shop.phone, 
                                location: shop.location, 
                                deliveryFee: shop.deliveryFee, 
                                deliveryTime: shop.deliveryTime, 
                                freeDelivery: shop.freeDelivery, 
                                rate: shop.rate, 
                                coverImage: shop.coverImage,
                                currency: shop.country.currency
                            } 
                        })
    },
    getImage(img) {
      //
      const link = `http://localhost:4000/api/images/vendor/${this.$store.getters['auth/getUser'].token}/*/${img}`
      // 'http://localhost:4000\\api\\streams\\'+token+'\\'+cpath.split("\\").join('*')+'\\'+ev.name
      return link
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

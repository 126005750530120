import gql from "graphql-tag";

export const GET_BUSINESS_PRODUCTS_QUERY = gql`
    query businessProducts($business: [String]!) {
      businessProducts(business: $business) {
            _id
            product
            category
            tags
            business
            image
            price
            margin
            discount
            type
            description
            details
            available
            visible
        }
    }
    ` 

export const GET_PRODUCTS_QUERY = gql`
  query allProducts($category: String, $business: String, $discount: String, $tags: String, $available: Boolean) {
    allProducts(category: $category, business: $business, discount: $discount, tags: $tags, available: $available) {
            _id
            product
            category
            tags
            business
            image
            price
            margin
            discount
            type
            description
            details
            available
            visible
    }
  }
` 

export const GET_PRODUCT_QUERY = gql`
  query product($product: String) {
    product(product: $product) {
            _id
            product
            category
            tags
            business
            image
            price
            margin
            discount
            type
            description
            details
            available
            visible
    }
  }
` 

<template>
    <div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        <div class=" p-5">
            
            <feather size="30" @click="goBack" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <feather @click="emptyCart" size="30" type="trash" class="mx-5 mx-2 dark" style="right:0;position:absolute" ></feather>
            <h4 class="title is-4 ml-5 pl-5">Checkout </h4>
            
            <div class="p-3">
                <h4 class="title is-4"> Order Summary </h4>
                <p class="subtitle "> {{ getCartBusiness }}</p>
            </div>
            <div class="card">
                <!-- {{items }} -->
            </div>
            <CartTemplate :products="items" :currency="getBusiness.country.currency"/>
            <!--  -->
            <div class="pl-4 pb-4 ">
                <p class="title is-6 has-text-primary" @click="goBack"><feather size="15" type="plus"></feather> Add more items</p>
            </div>
            <!-- Total -->
            <div class="card p-3">
                <h4 class="title is-5 p-2 pt-3">Total</h4>

                <div class="px-2">
                    <div v-if="(totalActualPrice !== totalPrice)" class="columns is-mobile mb-0">
                        <div class="column">
                            <p>Savings</p>
                        </div>
                        <div class="column has-text-right">
                            <p>({{ getBusiness.country.currency }}{{ (totalActualPrice - totalPrice).toFixed(2) }})</p>
                        </div>
                    </div>
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="has-text-weight-semibold">Subtotal</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="has-text-weight-semibold">{{ getBusiness.country.currency }}{{ (totalPrice).toFixed(2) }}</p>
                        </div>
                    </div>
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="">Delivery fee</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="">{{ (getBusiness.freeDelivery ? 'FREE' : getBusiness.country.currency+getBusiness.deliveryFee.toFixed(2)) }}</p>
                        </div>
                    </div>
                    <hr class="my-3 p-0">
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="has-text-weight-bold is-5">Total</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="has-text-weight-bold is-5">{{ getBusiness.country.currency }}{{ (getBusiness.freeDelivery ? totalPrice : totalPrice + getBusiness.deliveryFee).toFixed(2) }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card p-3 my-2 " >
                <div @click="$router.push({ name:'mapview', params: { refer: 'checkout' } })" style="display:flex;align-content: center;align-items: center" class="pb-2">
                    <feather type="map-pin" class="p-2"></feather> 
                    <p icon class=" is-5 is-grey has-text-weight-medium "> {{ (address.address.length > 30 ? address.address.substr(0,30).concat("...") : address.address) }} </p>
                </div>

                <!-- <figure class="image image is-2by1 m-2"> -->
                    <!-- <img src="https://bulma.io/images/placeholders/640x320.png"> -->
                    <GoogleMaps :radius="'20px'" :SearchVisible="false" :type="'address'" :height="'205px'" style="max-width:460px;margin:10px;" />
                <!-- </figure> -->
                <div class="mx-4 my-2 py-2">
                    <b-field>
                        <b-input v-model="note" placeholder="Delivery note" ></b-input>
                    </b-field>
                </div>
            </div>

            <div class="card p-3 my-2">
                <div class="columns is-mobile">
                    <div class="column is-two-thirds ">
                        <div class="is-flex is-justify-content-left is-align-items-center">
                            <feather :type="scheduleDate ? 'calendar' : 'clock'" class="p-2"></feather>
                            <h4 @click="clearSchedule" class=" is-6 has-text-weight-medium"> {{ scheduleDate ? formatAMPM(scheduleDate) :  deliveryTime ? deliveryTime : getBusiness.deliveryTime }} </h4>
                        </div>
                        <!-- <h6 class="">Same day delivery</h6> -->
                    </div>
                    <div class="column is-flex is-justify-content-center is-align-items-center mx-2">
                        <!-- <b-button @click="isModalActive = true" :type="scheduleDate ? 'is-primary' : ''" rounded size="is-small"> Schedule</b-button> -->
                        <b-button @click="scheduleOrder" :type="scheduleDate ? 'is-primary' : ''" rounded size="is-small"> Schedule</b-button>
                        
                    </div>
                </div>
            </div>

            <div class="card p-3 my-2">
                <h4 class="title is-5 p-2 pt-3 mb-3">Payment</h4>

                <div class="columns is-mobile" >
                    
                    <div :class="`column ${channel ? 'is-two-thirds' : 'is-full'}`" style="display:flex;align-content: center;align-items: center">
                        
                        <feather :type="channel ? (channel[0] == 'mobile_money' ? 'smartphone' : (channel[0] == 'card' ? 'credit-card' : (channel[0] == 'cash' ? 'dollar-sign' : '')) ) : 'toggle-left'" class="p-2"></feather> 
                        <p icon class=" is-5 is-grey has-text-weight-medium " @click="isOpen = !isOpen"> {{ channel ? (channel[0] == 'mobile_money' ? 'Mobile Money' : (channel[0] == 'card' ? 'Card Payment' : (channel[0] == 'cash' ? 'Cash - On delivery' : '' ) ) ) : 'Select payment method' }} </p>
                    </div>
                    <!-- <div v-if="channel" class="column m-3"> -->
                            <!-- <PayStackVue 
                                
                                style="display:"
                                :amount="parseFloat(getBusiness.freeDelivery ? totalPrice : totalPrice + getBusiness.deliveryFee) * 100"
                                :email="getUser.email"
                                :channels="channel"
                                ref="paymentBtn"
                            ></PayStackVue> -->
                    <!-- </div> -->
                </div>

                 <section>

                        <b-collapse animation="slide" :open="false" aria-id="contentIdForA11y1" v-model="isOpen">
                            <div class=" m-2" >
                                <div class="pl-0 p-1">
                                    <div style="display:flex;align-content: center;align-items: center">

                                    </div>
                                    <!-- <p class="link p-2 px-3">Cash  </p>  -->
                                    <div class="link p-2 px-5" :style="this.totalPrice > 100 ? 'text-decoration:line-through' : ''" @click="selectPay('cash')">Cash - On delivery </div>
                                    <div class="link p-2 px-5" @click="selectPay('mobile_money')">Mobile Money  </div> 
                                    <p class="link p-2 px-5" @click="selectPay('card')">Add New Card</p>
                                </div>
                            </div>
                        </b-collapse>
                        <div class="notification small text- " v-if="this.totalPrice > 100"> Total Order Amount is above the limit for paying with cash. Kindly use Momo or Card payment.</div>
                    </section>
            </div>
            
            <div class="card p-4 my-2">
                <div class="columns is-mobile p-2 pb-0">
                        <div class="column">
                            <p class="title is-5 has-text-weight-semibold">Total</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="title is-5 has-text-weight-semibold">{{ getBusiness.country.currency }}{{ (getBusiness.freeDelivery ? totalPrice : totalPrice + getBusiness.deliveryFee).toFixed(2) }}</p>
                        </div>
                </div>
                
                <b-button @click="completeOrder" type="is-primary" class="p-3 has-text-weight-medium is-5 title" size="is-medium" rounded expanded> Confirm Order</b-button>
            </div>

            <b-modal
                v-model="isModalActive"
                
                trap-focus
                :destroy-on-hide="false"
                :width="640"
                >
                <div class="modal-card animation-content">
                    <section class="modal-card-body is-titleless">
                        <!-- <div class="media"> -->
                            <!-- <div class="media-content">
                                <p><div>Select Schedule date!</div></p>
                                <div class="field">
                                    <div class="control"><input type="datetimepicker" placeholder="" maxlength="60" required="required" class="input"></div><p class="help is-danger"></p>
                                </div>
                            </div> -->
                            <b-field label="Select datetime">
                                <b-datetimepicker
                                    placeholder="Select a time"
                                    :min-datetime="minDatetime"
                                    :max-datetime="maxDatetime"
                                    v-model="datetime"
                                    >
                                </b-datetimepicker>
                            </b-field>
                        <!-- </div> -->
                    </section>
                    <footer class="modal-card-foot">
                        <b-button @click="isModalActive = false"> Cancel</b-button>
                        <b-button type="is-primary" @click="setSchedule"> Ok</b-button>
                    </footer>
                </div>
                
            </b-modal>

            <b-modal v-model="isPmtModalActive" width="460px" full-screen scroll="keep">
                <div class="p-4 has-bg-white is-flex is-justify-content-center is-align-items-center" style="height:100%;margin:auto;">
                       <PayStackVue 
                            v-if="payInt"
                            style="display:"
                            :amount="Math.round( (getBusiness.freeDelivery ? totalPrice : totalPrice + getBusiness.deliveryFee).toFixed(2) * 100)"
                            :email="getUser.email"
                            :channels="channel"
                            :currencyCode="getBusiness.country.currency"
                            :metadata="metadata"
                            :showAmountInButton="true"
                            :reference="reference"
                            :callback="callback"
                            :close="close"
                            ref="paymentBtn"
                        ></PayStackVue>
                    <!-- <div class="px-2 py-1"> -->
                        <!-- <b-button  type="is-primary has-text-weight-medium" class="is-submit-button" rounded expanded>Submit</b-button> -->
                        <!-- <b-button  style="position:absolute;bottom: 10px;width: 100%;margin:auto" type="mx-3 is-primary has-text-weight-medium" class="is-submit-button" rounded expanded>Add to Cart</b-button> -->
                    <!-- </div> -->
                    
                </div>
            </b-modal>
                

            <b-modal
                v-if="false"
                v-model="isPmtModalActive"
                trap-focus
                :destroy-on-hide="false"
                :width="640"
                >
                <div class="modal-card animation-content">
                    <section class="modal-card-body is-titleless">
                       
                    </section>
                    <!-- <footer class="modal-card-foot">
                        <b-button @click="isModalActive = false"> Cancel</b-button>
                        <b-button type="is-primary" @click="setSchedule"> Ok</b-button>
                    </footer> -->
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex"
    import CartTemplate from '../components/CartTemplate'
    import GoogleMaps from '../components/GoogleMaps'
    import axios from 'axios'
    import PayStackVue from '@/components/PayStack.vue'
    // import moment from 'moment'

    export default {
        name: "CheckoutPage",
        data() {
            const zone = new Date().toString().substring(28,33)
            const hours = parseInt(zone.substring(0,3))
            const minutes = parseInt(zone.substring(3))
            const min = new Date()
            min.setUTCDate(min.getUTCDate())
            min.setUTCHours(min.getUTCHours() + 1 + hours)
            min.setUTCMinutes(30 + minutes)
            min.setUTCSeconds(0)
            const max = new Date()
            max.setUTCDate(max.getUTCDate() + 7)
            // max.setUTCHours(min.getUTCHours() + 7 + hours)
            max.setUTCHours(20 + hours)
            max.setUTCMinutes(0 + minutes)
            max.setUTCSeconds(0)
            return {
                isModalActive: false,
                isPmtModalActive: false,
                isFullPage: true,
                business: null,
                businessData: null,
                isOpen: false,
                isLoading: false, 
                note: '',
                deliveryTime: '',
                scheduleDate: '',
                minDatetime: min,
                maxDatetime: max,
                datetime: new Date(),
                channel: null,
                payInt: null,
                metadata: null,
                getBusiness: {
                    country: {
                        currency: ''
                    },
                    deliveryFee: 0,
                    deliveryTime: '',
                    freeDelivery: false,
                    location: {
                        coordinates: [0, 0]
                    }
                }
            }
        },
        components: {
            CartTemplate,
            GoogleMaps,
            PayStackVue
        },
        computed: {
            ...mapState('cart', { items: state=> state.cart }),
            ...mapGetters('cart', ['totalPrice']),
            ...mapGetters('cart', ['totalActualPrice']),
            ...mapGetters('cart', ['getCartBusiness']),
            ...mapGetters('cart', ['cartCount']),
            ...mapGetters('auth', ['getUser']),
            ...mapGetters('vendors', ['address']),
            ...mapGetters('vendors', ['delivery']),
            ...mapGetters('orders', ['getNewOrder']),
            
            // getBusiness() {
            //     return this.$store.getters['vendors/currentBusiness'](this.business)
            // },
            reference(){
                let text = "";
                let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        
                for( let i=0; i < 10; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));
        
                return text;
            }
        },
        mounted() {
            //
            this.business = this.$store.getters['cart/getCartBusiness']
            //
            const bus = this.$store.getters['vendors/currentBusiness'](this.business)

            if (bus) {
                this.getBusiness = bus
                this.getDistanceTime()
            }

            else {
                
                this.$store.dispatch('vendors/getBusiness').then(res=>{

                    this.getBusiness = this.$store.getters['vendors/currentBusiness'](this.business)
                    this.getDistanceTime()

                })
            }

            if (this.cartCount == 0) {
                this.$router.push('/index')
            }

            this.setPay()
        },
        watch: {
            delivery() {
                this.getDistanceTime()
            },
            totalPrice() {
                if (this.totalPrice > 100) {
                    this.channel = ['mobile_money']
                } else {
                    this.channel = ['cash']
                }
            }
        },
        methods: {
            selectPay(channel) {

                if (channel == 'cash' && this.totalPrice > 100) {
                    return
                }
                this.isOpen = false
                this.channel = [""+channel+""]

                // this.$refs.paymentBtn.$el.click()
            },
            setPay() {
                this.email =  this.getUser.email
                this.amount = this.totalPrice

                // if (this.getUser.paymentMethod) {
                    this.channel = this.getUser.phone.includes('+233') ? (this.totalPrice < 100 ? [""+this.getUser.paymentMethod+""] : ["mobile_money"]) : ["card"]
                // }
            },
            getTimezone() {
                //
                const zone = new Date().toString().substring(28,33)
                const hr = parseInt(zone.substring(0,3))
                const mn = parseInt(zone.substring(3))
                
                return { hour: hr, minutes: mn }
            },
            goBack() {
                this.$router.back()
            },
            emptyCart() {

                this.$buefy.dialog.confirm({
                            title: 'Empty Cart',
                            message: `Are you sure you want to delete all items in you cart.`,
                            confirmText: 'Confirm',
                            type: 'is-danger',
                            // hasIcon: true,
                            onConfirm: () => {
                                this.$store.dispatch('cart/emptyCart')
                                this.$router.push('/index')
                            }
                        })

                
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toGMTString().substr(5,11)+', '+strTime;
            },
            clearSchedule() {
                this.scheduleDate = ''
                this.scheduleTime = ''
            },
            setSchedule() {
                this.isModalActive = false
            },
            scheduleOrder() {
                this.$buefy.dialog.prompt({
                                // title: 'Verify Code',
                                message: `Schedule a time!`,
                                inputAttrs: {
                                    placeholder: '',
                                    maxlength: 60,
                                    type: 'datetime-local',
                                    min: this.minDatetime.toJSON().substring(0,16),
                                    max: this.maxDatetime.toJSON().substring(0,16)
                                },
                                trapFocus: true,
                                onConfirm: (value) => {
                                    //
                                    this.scheduleDate = value

                                    //
                                    if (this.minDatetime.getUTCHours > (this.formatAMPM(this.scheduleDate)).getUTCHours || this.maxDatetime.getUTCHours < (this.formatAMPM(this.scheduleDate)).getUTCHours) {
                                    // if (this.formatAMPM('') > this.formatAMPM(this.scheduleDate) ) {
                                        //
                                        this.scheduleDate = ''

                                        return this.$buefy.toast.open('Oops, select future date!')
                                    }

                                }
                            })
            },
            getDistanceTime() {
                //
                axios.post('/order/distance', { origin: this.getBusiness.location.coordinates, destination: [this.address.location.lat, this.address.location.lng] })
                    .then( response => {

                        const res = response.data
                        // time manipulation + 5 incase of delays
                        const { time, distance } = res
                        //
                        this.deliveryTime = time.split(" ")[0]+' - '+(parseInt(time.split(" ")[0])+5)+' '+time.split(" ")[1]
                })
            },
            completeOrder() {

                // if (!this.channel) {
                //     //
                //     return this.$buefy.toast.open('Kindly select payment method')
                // }

                this.$buefy.dialog.confirm({
                            title: 'Comfirm Order',
                            message: `Are you sure you want to place this order.`,
                            confirmText: 'Place',
                            type: 'is-primary',
                            // hasIcon: true,
                            onConfirm: () => {
                                this.isLoading = true
                                // this.$store.dispatch('cart/emptyCart')
                                // let phone = this.getUser.phone.substr(0,4)

                                const data = {
                                    items: this.items,
                                    totalActualPrice: parseFloat(this.totalActualPrice.toFixed(2)), 
                                    totalPrice: parseFloat(this.totalPrice.toFixed(2)),
                                    deliveryFee: this.getBusiness.freeDelivery ? 0 : parseFloat(this.getBusiness.deliveryFee),
                                    total: parseFloat((this.getBusiness.freeDelivery ? this.totalPrice : this.totalPrice + this.getBusiness.deliveryFee).toFixed(2)),
                                    user: { countryCode: this.getUser.phone.substr(0,4),tel: this.getUser.phone.substr(4) },
                                    userName: this.getUser.firstname+' '+this.getUser.lastname,
                                    business: this.getCartBusiness,
                                    businessPhone: this.getBusiness.phone,
                                    locationName: this.getBusiness.locationName,
                                    location: this.getBusiness.location,
                                    payment: this.channel[0],
                                    deliveryAddressName: this.address.address,
                                    deliveryAddress: [this.address.location.lat, this.address.location.lng],
                                    deliveryNote: this.note,
                                    deliveryTime: this.deliveryTime,
                                    scheduled: this.scheduleDate ? this.scheduleDate : null
                                }

                                //
                                axios.post('/order/place', data)
                                .then( response => {

                                    const res = response.data
                                    this.isLoading = false

                                    //
                                    data.orderNo = res.orderNo
                                    this.$store.dispatch('orders/placeOrder', data).then(()=>{

                                        //
                                        if (this.getNewOrder.orderNo){
                                            this.metadata = { orderNo: res.orderNo }
                                            
                                            //
                                            if (['mobile_money','card'].includes(this.channel)) {
                                                this.payInt = true
                                                this.isPmtModalActive = true

                                                //
                                                setTimeout(()=>{
                                                
                                                    // this.$router.push('/tracker')
                                                    //
                                                    this.$buefy.toast.open('Kindly make payment to complete order.')
            
                                                }, 1200)
                                            } 
                                            
                                            else {
                                                
                                                this.$buefy.toast.open("Successfully Placed.")

                                                // clear 
                                                this.$store.dispatch('cart/emptyCart')
                                                //
                                                this.$router.push('/tracker')
                                            }
                                        }
                                    })
                                    
                                    // // clear 
                                    // this.$store.dispatch('cart/emptyCart')

                                    // if (this.scheduleDate) {
                                    //     //
                                    //     this.$buefy.toast.open('Order Scheduled Successfully.')

                                    // } else {
                                    //     //
                                    //     this.$buefy.toast.open('Order Tracking in progress.')
                                    // }
                                    //
                                    

                                    // this.$buefy.dialog.confirm({
                                    //     title: 'Order Tracking',
                                    //     message: res.message+', Track your order!',
                                    //     onConfirm: () => {
                                    //         //
                                    //         setTimeout(()=>{
                                                
                                    //             this.$router.push('/tracker')
                                    //         }, 800)
                                    //     }
                                    // })

                                }).catch(error=>{
                                    this.isLoading = false
                                    this.$buefy.dialog.alert(error.response.data)
                                    console.log(error.response)
                                })

                                // setTimeout(()=>{
                                //     this.isLoading = false
                                //     this.$router.push('/tracker')
                                // }, 1500)
                            }
                        })
            },
            callback: function(response){
                this.$buefy.toast.open("Successfully paid.")
                console.log(response)

                // clear 
                this.$store.dispatch('cart/emptyCart')
                //
                this.$router.push('/tracker')
            },
            close: function(){
                console.log("Payment closed")
                //
                this.isPmtModalActive = false

                //
                // delete pending order
                axios.post('/order/delete', {  orderNo: this.metadata.orderNo, user: { countryCode: this.getUser.phone.substr(0,4),tel: this.getUser.phone.substr(4) }
                                        })
                                        .then( response => {

                                            //
                                            this.$buefy.toast.open("Oops failed, try again!")

                                            // const res = response.data
                                            // this.isLoading = false
                                            console.log(response.data)

                                        })

            }
        }
    }
</script>

<style lang="scss" scoped>
    .link:hover, .link:focus {
        background: grey;
        background: #f4f4f4;
        border-radius: 1.2rem;
    }
</style>
import gql from "graphql-tag";

export const GET_BUSINESSES_QUERY = gql`
  query businesses {
    businesses {
        _id
        business
        type
        coverImage
        location
        locationName
        country
        phone
        freeDelivery
        deliveryFee
        deliveryTime
        menuDiscount
        ratings
        createdAt
    }
  }` 

export const GET_LOCATION_BUSINESS_QUERY = gql`
  query getBusiness($location: [Float]!) {
    getBusiness(location: $location) {
        _id
        business
        type
        coverImage
        location
        locationName
        country
        phone
        freeDelivery
        deliveryFee
        deliveryTime
        menuDiscount
        ratings
        createdAt
    }
  }
` 
export const GET_BUSINESS_QUERY = gql`
  query business($business: String) {
    business(business: $business) {
        _id
        business
        type
        coverImage
        location
        country
        phone
        freeDelivery
        deliveryFee
        deliveryTime
        menuDiscount
        ratings
        createdAt
    }
  }`

<template>
    <div :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
        <feather @click="goBback" type="arrow-left" size="22" class="p-2 m-5 close"></feather>
        <div class="p-2" v-if="currentProduct.business">
            <!-- `${require('@/assets/img/'+currentProduct.image+'')}` -->
               <b-image
                    :src="currentProduct.image" :alt="currentProduct.image"
                    webp-fallback=".jpg"
                    :ratio="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? '4by3' : '5by3'))"
                ></b-image>
                <div class="content p-1 mb-1">
                    <div class=" pb-1">
                        
                    </div>
                    <!-- <hr> -->
                    <div class="p-5 card ">
                        <h3 class="title is-3 mb-2">{{ currentProduct.product }}</h3>
                        <p v-if="currentProduct.discount !== 0" class="mx-1 mb-3 is-4 has-text-weight-medium" >
                            <span class="mr-1" style="text-decoration:line-through"> {{ currentProduct.currency }}{{ formatPrice(currentProduct.sprice) }} </span> 
                            <b-tag type="is-primary" rounded> {{ currentProduct.currency }}{{ formatPrice( getDiscount(currentProduct.sprice, currentProduct.discount) ) }} </b-tag> 
                        </p>
                        <p v-else class="mx-1 is-4  mb-2 has-text-weight-medium">{{ currentProduct.currency }}{{ formatPrice(currentProduct.sprice) }}</p>
                        <!-- <hr> -->
                        <div class="py-2"></div>
                        <p class="mx-1 mb-2 is-3 has-text-grey"><b-tag rounded type="is-primary" size="is-"> {{ currentProduct.type }} </b-tag> {{ currentProduct.details }} </p>
                        <p class="mx-1">{{ currentProduct.description }} </p>
                        <div> <b-tag class="mr-2 is-6" style="color:gray;" v-for="(tags, index) of currentProduct.tags" :key="index">{{ tags }}</b-tag> </div>
                        <div style="width: 50%;margin:auto">
                            <div class="columns is-mobile text-center" style="margin:auto">
                                <div class="column">
                                    <b-button :disabled="alreadyInCart ? currentProduct.qty <= 0 : currentProduct.qty == 1" @click="lessQty" rounded icon-left="minus"></b-button>
                                </div>
                                <div class="column py-4 m-1">
                                    <p>{{ currentProduct.qty  }}</p>
                                </div>
                                <div class="column">
                                    <b-button @click="addQty" rounded icon-left="plus"></b-button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class=" cart-cover" :style="(screenWidth <= 560 ? '' : (screenWidth <= 986 ? '' : 'width: 890px; margin: auto;') )"> -->
                        <!-- <div class="pt-2 px-0 cart-cover"> -->
                            <!-- <b-button @click="addToCart" :type="(alreadyInCart) ? 'is-dark' : 'is-primary'" class="p-3 has-text-weight-medium addtocart" size="is-medium" rounded expanded> {{ (alreadyInCart ? 'Update Cart' : 'Add to Cart') }} - {{ currency+formatPrice(currentProduct.totalPrice)}}</b-button> -->
                        <!-- </div> -->
                    </div>
                    <div class=" cart-cover" :style="(screenWidth <= 560 ? '' : (screenWidth <= 986 ? '' : 'width: 890px; margin: auto;') )">
                        <!-- <div class="pt-2 px-0 cart-cover"> -->
                        
                        <b-button @click="addToCart" :type="(alreadyInCart) ? (currentProduct.qty <= 0 ? 'is-danger' : 'is-dark') : 'is-primary'" class="p-3 has-text-weight-medium is-5 title" size="is-medium" rounded expanded>
                            
                        {{ (alreadyInCart ? (currentProduct.qty <= 0 ? 'Remove' : 'Update Cart') : 'Add to Cart') }} {{ currentProduct.qty <= 0 ? '' : '- '+currentProduct.currency+formatPrice(currentProduct.totalPrice)}}
                            
                        </b-button>
                    </div>
                </div>
                 
                
                
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        name: "ProductPage",
        data() {
            return {
                screenWidth: 0,
                currentProduct: {
                    id: '',
                    available: '',
                    business: '', 
                    image: '',
                    product: '', 
                    discount: '',
                    price: '',
                    margin: '',
                    sprice: '',
                    type: '',
                    details: '',
                    description: '',
                    totalPrice: 0,
                    qty: 1,
                    currency: ''
                },
                cartSubmitted: false
            }
        },
        computed: {
            // ...mapGetters('products', ['allProducts']),
            ...mapGetters('cart', ['getCartBusiness']),
            
            alreadyInCart() {
                // console.log(this.$store)
                return this.$store.getters['cart/alreadyInCart'](this.currentProduct.id)
            },
            inCart() {
                // console.log(this.$store)
                const cart = this.$store.getters['cart/inCart'](this.currentProduct.id)
                return cart
            },
            // alreadyAddedtoWishlist() {
            //     return this.$store.getters.cart.alreadyInWishlist(this.id)
            // } 
        },
        mounted(){
            // console.log(this.$route)
            //
            // this.currentProduct = this.$route.params.product

            //
            if (Object.keys(this.$route.params).length !== 0) {
                
                const data = {
                        link: 'product',
                        data: this.$route.params
                    }

                this.currentProduct.id = this.$route.params.id
                this.currentProduct.available = this.$route.params.available
                this.currentProduct.business = this.$route.params.business
                this.currentProduct.image = this.$route.params.image
                this.currentProduct.product = this.$route.params.product
                this.currentProduct.discount = this.$route.params.discount
                this.currentProduct.price = this.$route.params.price
                this.currentProduct.margin = this.$route.params.margin
                this.currentProduct.sprice = this.$route.params.sprice
                this.currentProduct.tags = this.$route.params.tags
                this.currentProduct.totalPrice = (this.currentProduct.discount > 0 ? ((100 - this.currentProduct.discount)/100) * this.currentProduct.sprice : this.currentProduct.sprice)
                this.currentProduct.type = this.$route.params.type
                this.currentProduct.details = this.$route.params.details
                this.currentProduct.description = this.$route.params.description
                this.currentProduct.currency = this.$route.params.currency

                this.$store.dispatch('pages/setPage', data)
            }
            else {
                const data = this.$store.getters['pages/getPage']('product')

                if (data !== null) {
                        // this.form = data.search
                        this.currentProduct.id = data.id
                        this.currentProduct.available = data.available
                        this.currentProduct.business = data.business
                        this.currentProduct.image = data.image
                        this.currentProduct.product = data.product
                        this.currentProduct.discount = data.discount
                        this.currentProduct.price = data.price
                        this.currentProduct.margin = data.margin
                        this.currentProduct.sprice = data.sprice
                        this.currentProduct.tags = data.tags
                        this.currentProduct.totalPrice = (this.currentProduct.discount > 0 ? ((100 - this.currentProduct.discount)/100) * this.currentProduct.sprice : this.currentProduct.sprice)
                        this.currentProduct.type = data.type
                        this.currentProduct.details = data.details
                        this.currentProduct.description = data.description
                        this.currentProduct.currency = data.currency
                }
            }
            

            //
            if (this.alreadyInCart) {
                this.currentProduct.qty = parseInt(this.inCart.unit)
                this.currentProduct.totalPrice = parseInt(this.inCart.total)
            }

            this.updateScreenWidth();
            this.onScreenResize();
            // this.getProducts()
        },
        methods: {
            lessQty() {
                // if (this.qty <= 1) {
                this.currentProduct.qty--
                this.currentProduct.totalPrice = this.currentProduct.qty * (this.currentProduct.discount > 0 ? ((100 - this.currentProduct.discount)/100) * this.currentProduct.sprice : this.currentProduct.sprice)
                // }
            },
            addQty() {
                this.currentProduct.qty++
                this.currentProduct.totalPrice = this.currentProduct.qty * ( this.currentProduct.discount > 0 ? ((100 - this.currentProduct.discount)/100) * this.currentProduct.sprice : this.currentProduct.sprice)
                
            },
            formatPrice(price) {
                return parseFloat(price).toFixed(2)
            },
            getDiscount(price, disc) {
                return ((100 - disc)/100) * price;
            },
            loadProduct(product){
                this.currentProduct = product
                //open
                this.isCardModalActive = true
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            goBback() {
                this.$router.back()
            },
            getImage(img) {
                //
                const link = `http://localhost:4000/api/images/product/${this.$store.getters['auth/getUser'].token}/*/${img.split('/')[1]}`
                // 'http://localhost:4000\\api\\streams\\'+token+'\\'+cpath.split("\\").join('*')+'\\'+ev.name
                return link
            },
            addToCart() {

                // if (this.alreadyAddedtoCart) {
                //     return
                // }

                if (this.getCartBusiness !== null) {

                    if (this.getCartBusiness !== this.currentProduct.business){
                        
                    // })
                        this.$buefy.dialog.confirm({
                            title: 'Starting a new Order?',
                            message: `This will clear your <b>${this.getCartBusiness} </b> Order.`,
                            confirmText: 'Confirm',
                            type: 'is-danger',
                            // hasIcon: true,
                            onConfirm: () => {
                                // empt cart
                                this.$store.dispatch('cart/emptyCart')
                                //

                                let formOutput = {
                                    available: this.currentProduct.available,
                                    id: this.currentProduct.id,
                                    discount: this.currentProduct.discount,
                                    business: this.currentProduct.business,
                                    product: this.currentProduct.product,
                                    tags: this.currentProduct.tags,
                                    image: this.currentProduct.image,
                                    unit: this.currentProduct.qty,
                                    price: this.currentProduct.price,
                                    margin: this.currentProduct.margin,
                                    sprice: this.currentProduct.sprice
                                };
                                
                                this.$store.dispatch('cart/addToCart', formOutput)

                            }
                        })
                    
                        return
                    }
                }

                let formOutput = {
                    available: this.currentProduct.available,
                    id: this.currentProduct.id,
                    discount: this.currentProduct.discount,
                    business: this.currentProduct.business,
                    product: this.currentProduct.product,
                    tags: this.currentProduct.tags,
                    image: this.currentProduct.image,
                    unit: this.currentProduct.qty,
                    price: this.currentProduct.price,
                    margin: this.currentProduct.margin,
                    sprice: this.currentProduct.sprice
                };
                

                this.cartSubmitted = true;
                this.$store.dispatch('cart/addToCart', formOutput)

                if (this.currentProduct.qty <= 0) {
                    this.addQty()
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .addtocart {
        padding: 1.7rem !important;
    }

    .cart-cover {
        position: fixed;
        width: calc(100% - 28px);
        // left: 0;
        // padding-bottom: 20px;
        // margin-bottom: 10px;
        bottom: 10px;
    }
</style>
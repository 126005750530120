import { render, staticRenderFns } from "./verifyotp.vue?vue&type=template&id=cd35be50"
import script from "./verifyotp.vue?vue&type=script&lang=js"
export * from "./verifyotp.vue?vue&type=script&lang=js"
import style0 from "./verifyotp.vue?vue&type=style&index=0&id=cd35be50&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
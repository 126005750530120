<template>
    <div>
        <!-- <div class="px-3 py-3 top-nav" style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;position:fixed;width:100%;background:white"> -->
        <div class="px-2 py-1 top-nav main is-flex is-align-items-center" style="">
            <!-- <b-button icon-left="menu" @click="open = true"></b-button> -->
            <!-- <feather size="22" type="gift" class="link- ml-2 link p-3 white" @click="open = true"></feather> -->
            <router-link to="/gifts" size="22" class=""><feather size="22" type="gift" class="link- ml-2 link px-3 has-text-white"></feather></router-link>
            <!-- <feather type="user" class="mx-2 link p-3" @click="open = true"></feather> -->

            <div class="text-">
                <!-- <img
                src="@/assets/img/logo.png"
                alt="Grenshelve"
                class=" logo"
                /> -->

                <router-link class="has-text-white" to="/mapview" style="display:flex;">
                    <!-- <feather size="22" class="p-3 px-2 " type="map-pin"></feather>  -->
                    <p class="py-3 mx-3 px-3 is-dark"> {{ address.address !== '' ? formatAddress(address.address) : 'Delivery Address' }} </p> 
                    <!-- <feather class="py-3 pr-3" type="chevron-down"></feather> -->
                </router-link>
                
                <!-- <h3 @click="isCardModalActive=true"  class="title is-4 py-3">Grenshelve</h3> -->
                <!-- <p class="subtitle is-6">integrate health</p> -->
            </div>

            <!-- <b-modal v-model="isCardModalActive" full-screen scroll="keep">
                <div class="p-5">
                    <h4>Address</h4>
                </div>
            </b-modal> -->
            
            <div @click="gotoCart()" size="22" :class="`is-white ${cartCount > 0 ? `is-warning` : ''}`">
                <span v-if="cartCount > 0" style="position:absolute;right:0" class="mr-5 p-1 tag is-danger mt- "> {{ cartCount }} </span>
                <feather type="shopping-cart" class="link- mr-4 link p-3 has-text-white"></feather> 
            </div>

            <div style="display:none">
                
                <b-dropdown position="is-bottom-left" aria-role="list">
                    <template #trigger>
                        <feather class="mx-2 p-3 link" type="octagon"></feather>
                        <!-- <b-button @click="openUser = true"> </b-button> -->
                        <!-- <b-button
                            label="Click me!"
                            type="is-primary"
                            :icon-right="active ? 'menu-up' : 'menu-down'" /> -->
                    </template>


                    <b-dropdown-item aria-role="listitem"><router-link to="/account"> Account</router-link></b-dropdown-item>
                    <b-dropdown-item aria-role="listitem"><router-link to="/support"> Support</router-link></b-dropdown-item>
                    <b-dropdown-item aria-role="listitem"><router-link to="/signin"> Sign out</router-link></b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <hr class="m-0">

    </div>
</template>

<script>

    import { userService } from '../services';
    import { mapGetters } from 'vuex'
 
    export default {
        name: "SideNav",
        data() {
            return {
                openUser: false,
                open: false,
                overlay: false,
                fullheight: true,
                fullwidth: false,
                screenWidth: 0,
                right: false,
                isCardModalActive: false
            }
        },
        computed: {
            ...mapGetters('cart', ['cartCount']),
            ...mapGetters('vendors', ['address'])
        },
        mounted() {
            
            this.updateScreenWidth();
            this.onScreenResize();
        },
        methods: {
            run(){
                // console.log('hiiiii')
            },
            logOut() {
                this.open = false;
                //
                userService.logout()
                //
                this.$router.push('/signin')
            },
            gotoCart() {
                if (this.cartCount > 0){
                    this.$router.push('/cart')
                } else {
                    this.$buefy.toast.open('Cart is empty!')
                }
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatAddress(address) {
                //
                const len = address.length

                if (this.screenWidth <= 560 ) {
                    
                    return len > 25 ? address.substr(0, 25)+'...' : address
                } else {
                    
                    return address
                }

                // return address.split(',')[0]+','+address.split(',')[1].substr(0,22).concat('...')

            }
        }
    }
</script>

<style lang="scss" >
// custom
@import '@/assets/scss/custom_variables.scss';

.white {
    color: $white;
}

.main {
    background-color: $primary;
}

.link-bg {
    background: rgb(246, 246, 246);
    border-radius: 1.10rem;
}

.top-nav {
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;
}

.link {
    cursor: pointer;
}

.logo { 
    margin: auto;
    width: 200px;
}

.menu-list a {
    padding: 1rem 0.75rem !important;
}

.subtitle {
    font-size: 0.85rem !important;
    color: rgba($color: #828282, $alpha: 1.0);
}
</style>
"use strict";

import Vue from 'vue';
import axios from "axios";
import { authHeader } from '../helpers/auth-header'

let AUTH_TOKEN = authHeader()['Authorization']

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';'http://localhost:4000/api/'
// axios.defaults.baseURL = 'http://localhost:4000/api/';
// axios.defaults.baseURL = 'http://localhost:4000/rest/';
axios.defaults.baseURL = 'https://greenserver.fly.dev/rest/';
// axios.defaults.baseURL = 'http://192.168.0.172:4000/api/';
// axios.defaults.baseURL = 'http://192.168.1.1113:4000/api/';
axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    //
    // const CurrentUser = localStorage.getItem('user');
    //   let token = ( (CurrentUser) ? JSON.parse(CurrentUser).token : null)
    //   let islogged = ( (CurrentUser) ? JSON.parse(CurrentUser).islogged : null)
    //   let refresh_token = ( (CurrentUser) ? JSON.parse(CurrentUser).refresh_token : null)

    // 
    // if (error.response !== null ) {

      //    if (error.response.status == 401 && islogged == "true"){

      //       try {
              
      //         // initiate a refresh request
      //         store.dispatch("authentication/refresh", { token, refresh_token })
      
      //       } catch (error) {
      //         //
      //         if (error.response && error.response.data){
      //           return Promise.reject(error.response.data);
      //         }
      
      //         Promise.reject(error);
      //       }
      //    }
    // }
    console.log('ol')

    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;

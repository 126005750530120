<template>
    <div>
        <!-- <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading> -->
        <div class=" p-5"  :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            
            <feather size="30" @click="goBack" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <feather v-if="cartCount > 0" @click="emptyCart" size="30" type="trash" class="mx-5 mx-2 dark" style="right:0;position:absolute" ></feather>
            <h4 class="title is-4 ml-5 pl-5">Cart </h4>
            
            <div class="p-3">
                <!-- <h4 class="title is-4"> Order Summary </h4> -->
                <p class="is-5 "> {{ getCartBusiness }}</p>
            </div>
            <div class="card">
                <!-- {{items }} -->
            </div>
            <CartTemplate :products="items" :currency="getBusiness.country.currency"/>
            <!--  -->
            <!-- Total -->
            <div v-if="cartCount == 0" class="p-5 m-3 text-center">
                <p class="">Cart is empty</p>
            </div>
            <div v-if="cartCount > 0" class="card p-3">
                <h4 class="title is-5 p-2 pt-3">Total</h4>

                <div class="px-2">
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="has-text-weight-semibold">Subtotal</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="has-text-weight-semibold">{{ getBusiness.country.currency }}{{ (totalPrice).toFixed(2) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="cartCount > 0" class="card p-4 my-2">
                <div class="columns is-mobile p-2 pb-0">
                        <div class="column">
                            <p class="title is-5 has-text-weight-semibold">Total</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="title is-5 has-text-weight-semibold">{{ getBusiness.country.currency }}{{ totalPrice.toFixed(2) }}</p>
                        </div>
                </div>
                <b-button @click="$router.push('/checkout')" type="is-primary" class="p-3 has-text-weight-medium is-5 title" size="is-medium" rounded expanded> Proceed to Checkout</b-button>
            </div>
            
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex"
    import CartTemplate from '../components/CartTemplate'

    export default {
        name: "CartPage",
        data() {
            return {
                business: '',
                businessData: null,
                isOpen: true,
                isLoading: false,
                screenWidth: 0
            }
        },
        components: {
            CartTemplate
        },
        computed: {
            ...mapState('cart', { items: state=> state.cart }),
            ...mapGetters('cart', ['totalPrice']),
            ...mapGetters('cart', ['getCartBusiness']),
            ...mapGetters('cart', ['cartCount']),
            getBusiness() {
                return this.$store.getters['vendors/currentBusiness'](this.business)
            }
        },
        mounted() {
            //
            if (!this.getBusiness){
                //
                this.$store.dispatch('vendors/getBusiness')
            }

            this.business = this.$store.getters['cart/getCartBusiness']
            this.updateScreenWidth();
            this.onScreenResize();
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            goBack() {
                this.$router.back()
            },
            emptyCart() {

                this.$buefy.dialog.confirm({
                            title: 'Empty Cart',
                            message: `Are you sure you want to delete all items in you cart.`,
                            confirmText: 'Confirm',
                            type: 'is-danger',
                            // hasIcon: true,
                            onConfirm: () => {
                                this.$store.dispatch('cart/emptyCart')
                                this.$router.push('/index')
                            }
                        })

                
            }
        }
    }
</script>

<style lang="scss" scoped>
    .link:hover, .link:focus {
        background: grey;
        background: #f4f4f4;
        border-radius: 1.2rem;
    }
</style>
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
// import './plugins/bootstrap-vue'
import './plugins/axios'
// import './plugins/maps'
// import './plugins/bootstrap-vue'
import router from './router'
import { store } from './store';
import App from './App.vue'
import VueFeather from 'vue-feather';
import VueSweetalert2 from 'vue-sweetalert2';
import Notifications from 'vue-notification';
import Toasted from 'vue-toasted';
import paystack from 'vue-paystack';
import OtpInput from "@bachdgvn/vue-otp-input";
import { apolloProvider } from './graphql/apollo'
import VueCountryCode from "vue-country-code";

import * as VueGoogleMaps from "vue2-google-maps"

Vue.use(VueGoogleMaps, {
  load: {
    region: 'gh',
    key: "AIzaSyDdN_FSksw-ceViQPFZVNjYssw6j8k_CfE",
    libraries: "places",
  }
});

import vSelect from 'vue-select';
Vue.component('v-select', vSelect)

Vue.use(VueCountryCode);

Vue.use(Notifications)


Vue.use(Toasted,{
  iconPack: 'fontawesome',
});

Vue.use(paystack);

Vue.component(VueFeather.name, VueFeather);

Vue.use(VueSweetalert2);



Vue.component("v-otp-input", OtpInput);

import 'sweetalert2/dist/sweetalert2.min.css';
import Buefy from 'buefy'

// include
import 'buefy/dist/buefy.css'

import './assets/scss/app.scss'
// custom
import './assets/scss/custom.scss'
import './assets/scss/vendors.scss'
import './registerServiceWorker'

Vue.use(Buefy)
// import 'buefy/dist/buefy.css'

Vue.config.productionTip = false

new Vue({
  router,
  apolloProvider,
  store,
  render: h => h(App),
}).$mount('#app')

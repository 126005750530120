
//
import { GET_BUSINESS_QUERY, GET_LOCATION_BUSINESS_QUERY } from '../../graphql/business'
import { apolloClient } from '../../graphql/apollo'
import { store } from '../../store'
import axios from 'axios';

const location = localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) :  false;
const initialState = location
    ? location
    : { location: '', address: '', addressList: [] };

export const vendors = {
    namespaced: true,
    state: { 
        address1: [5.6305863,-0.1474174],
        address: initialState.address,
        location: initialState.location,
        deliveryAddress: '',
        deliveryLocation: '',
        addressList: initialState.addressList,
        vendors: [],
        vendor: []
    },
    getters: {
        discountedMenu: (state) => {
            //
            const data = state.vendors
        
            //
            const disContent = []
        
            for (let index = 0; index < data.length; index++) {
              // const element = array[index];
              if (data[index].menuDiscount > 0) {
                //
                disContent.push(data[index])
              }
            }
        
            // sort in desc
            disContent.sort((a, b) => b.menuDiscount - a.menuDiscount)
        
            return disContent
          },
          freeDelivery: (state) => {
            //
            const data = state.vendors
        
            //
            const freeContent = []
        
            for (let index = 0; index < data.length; index++) {
              // const element = array[index];
              if (data[index].freeDelivery) {
                //
                freeContent.push(data[index])
              }
            }
        
            return freeContent
          },
          mostRated: (state) => {
            //
            const data = state.vendors
            const rateContent = []
        
            for (let index = 0; index < data.length; index++) {
              //
              if (data[index].rate > 0) {
                //
                if (data[index].rate >= 4.5) {
                  //
                  rateContent.push(data[index])
                }
                // data.push(data[index])
              }
            }
            // sort in desc
            rateContent.sort((a, b) => b.rate - a.rate)
        
            return rateContent
          },
          newBusiness: (state) => {
            //
            const data = state.vendors
            const newContent = []
        
            for (let index = 0; index < data.length; index++) {
              //
              if (parseFloat(data[index].ratings.length) === 0) {
                //
                newContent.push(data[index])
                // data.push(data[index])
              }
            }
            // sort in desc
            // newContent.sort((a, b) => b.rate - a.rate)
        
            return newContent
          },
          allBusiness: (state) => {
            //
            return state.vendors
          },
          business: (state) => {
            //
            return state.vendor
          },
          allShortDelivery: (state) => {
            //
            const data = state.vendors
            const newContent = []
        
            for (let index = 0; index < data.length; index++) {
              //
              if (index) {
                //
                newContent.push(data[index])
                // data.push(data[index])
              }
            }
        
            // sort in desc
            newContent.sort((a, b) => a.deliveryTime.substr(0, 2) - b.deliveryTime.substr(0, 2))
        
            return newContent
          },
          currentBusiness: state => (name) => {
            // if (business) { return }
        
            const results = state.vendors.find(({business}) => business.includes(name))
        
            return results
          },
          address: (state) =>  {
            return { address: state.address, location: state.location }
          },
          delivery: (state) =>  {
            return (state.deliveryAddress || state.deliveryLocation) ? { address: state.deliveryAddress, location: state.deliveryLocation } : { address: state.address, location: state.location }
          },
          allAddresses: (state) => {
            //
            return state.addressList
          },
          getAddress: (state) => (name) => {
            
            const results = state.addressList.find(({address}) => address.toLowerCase().includes(name.toLowerCase()))
        
            return results
          },
          parseAddress: (addressObject) => {
            //
            const parsed = [addressObject.lat, addressObject.lng]

            return parsed
          }

    },
    actions: {
        async getBusiness({ state,commit,getters }, address ) {
            //
            let resp
            let data
            //
            if (state.address !== "") {
              //
              const loc = [state.location.lat, state.location.lng]

              resp = await apolloClient.query({ query: GET_LOCATION_BUSINESS_QUERY, variables: { location: loc } })
              data = resp.data.getBusiness

            }  else {
                //
                // resp = await apolloClient.query({ query: GET_BUSINESS_QUERY })
                // data = resp.data.businesses
                data = []
            }

            (function () {
            for (let index = 0; index < data.length; index++) {
                // const element = array[index];
                const rateLen = data[index].ratings.length

                if (rateLen > 0) {
                // sum ratings
                const rate = (data[index].ratings.reduce((a, b) => a + b, 0)) / rateLen
                // to 2 decimalplaces
                data[index].rate = parseFloat(rate.toFixed(1))

                // data.push(data[index])
                }
            }
            })()

            commit('updateVendors', data)

            // check in new location has that vendor
            let cartBusiness = store.getters['cart/getCartBusiness']
            //
            if (cartBusiness) {
              //
              if (!store.getters['vendors/currentBusiness'](cartBusiness)) {
                //
                store.dispatch('cart/emptyCart')
              }
            }
        },
        async getOneBusiness({ state,commit,getters }, vendor ) {
          //
          let resp
          let data
          //
          if (state.vendor !== "") {

            resp = await apolloClient.query({ query: GET_BUSINESS_QUERY, variables: { business: vendor } })
            data = resp.data.business

          } else {
              //
              // resp = await apolloClient.query({ query: GET_BUSINESS_QUERY })
              // data = resp.data.businesses
              data = []
          }

          commit('updateVendor', data)
        },
        async getPlace({ state, commit }, data) {

          //
          const places = await axios.get('/user/location',  data);

          //
          (function () {
            //
            for (let index = 0; index < places.length; index++) {
              //
              data[index].location = { 
                lat: data[index].location[0],
                lng: data[index].location[1]
               }
            }
          })()

          //
          commit('setPlace', places)

        },
        async placeChecker({ state, commit }, data) {
          if (data.type == 'checkout'){
            // check in new location has that vendor
            let cartBusiness = store.getters['cart/getCartBusiness']
            //
            let resp
            let fetch
            //
            if (data.address !== "") {
              //
              const loc = [data.location.lat, data.location.lng]
              
              //
              resp = await apolloClient.query({ query: GET_LOCATION_BUSINESS_QUERY, variables: { location: loc } })
              fetch = resp.data.getBusiness

            }  else {
                //
                // resp = await apolloClient.query({ query: GET_BUSINESS_QUERY })
                // data = resp.data.businesses
                fetch = []
            }

            //
            if (!fetch.find(({business}) => business.includes(cartBusiness))) {
              //
              return false
            } else {
              return true
            }
          }
        },
        async addPlace({ state, commit }, data) {

          // console.log(data)

          if (typeof(data) === 'object' || data.address || data.location) {
            //
            const found = state.addressList.find((place)=> place.address.includes(data.address))

            if (state.address !== data.address) {
              //
              commit('addPlace', data)
              
              // check in new location has that vendor
              // let cartBusiness = store.getters['cart/getCartBusiness']
              // //
              // if (!store.getters['vendors/currentBusiness'](cartBusiness)) {
              //   //
              //   store.dispatch('cart/emptyCart')
              // }

              setTimeout(async()=>{
                //
                if (state.vendors.length > 0) {
                  //
                  const run = await axios.post('/user/location',  { address: data.address, location: [data.location.lat, data.location.lng] } )
                }
              }, 10000)
            }
            
          }
        },
        addDelivery({ commit }, data) {

          if (typeof(data) === 'object' || data.address || data.location) {
            //
            commit('addDelivery', data)
          }
        }
    },
    mutations: {
        updateVendors: (state, data) => {
            state.vendors = data
        },
        updateVendor: (state, data) => {
          state.vendor = data
        },
        setPlace: (state, data) => {
          state.addressList = data
        },
        addPlace: (state, data) => {
          // set
          state.address = data.address
          state.location = data.location
          // check before push
          const found = state.addressList.find((place)=> place.address.includes(data.address))

          if (!found) {
            state.addressList.push(data)
          }

          localStorage.setItem('location', JSON.stringify({ location: data.location, address: data.address, addressList: state.addressList }))
          
        },
        addDelivery: (state, data) => {
          // set
          state.deliveryAddress = data.address
          state.deliveryLocation = data.location
          
        }
    }
}